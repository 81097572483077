import React, { Fragment } from "react";
import Banner from "../../Layouts/Banner/index";
import Gallery from "../../Layouts/Gallery/index";
import Features from "../../Layouts/Features/index";
import Aboutus from "../../Layouts/Aboutus/index";
import Service from "../../Layouts/Services/index";
import FeaturedProject from "../../Layouts/FeaturedProject/index";
import Testimonial from "../../Layouts/Testimonial/index";
function Index() {
  return (
    <Fragment>
      <Banner />
      <Gallery />
      <Features />
      <Aboutus />
      <Service />
      <FeaturedProject />
      <Testimonial />
    </Fragment>
  );
}

export default Index;
