import React, { Fragment } from "react";
import Image from "../../assets/uploads/testimonial-image.png";

function Index({ title, desc, post, image }) {
  return (
    <Fragment>
      <div className="testimonial-card">
        <div className="testimonial-card__header">
          <div className="testimonial-card__header-image">
            <figure>
              <img src={image} alt="testimonial card"></img>
            </figure>
          </div>
          <div className="testimonial-card__header-title">
            <h3>{title}</h3>
            <p>{post}</p>
          </div>
        </div>
        <p className="testimonial-card-desc mt-2">{desc}</p>
      </div>
    </Fragment>
  );
}

export default Index;
