import React, { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Card from "../../Components/Card/index";
import Consult from "../../assets/icons/avatar.svg";
import Rethink from "../../assets/icons/rethink.svg";
import Sketch from "../../assets/icons/sketch.svg";
import BgImage from "../../assets/uploads/electrical-map.png";
function Index() {
  return (
    <Fragment>
      <section className="theme-section features" id="works">
        <div className="features-bg">
          <figure>
            <img src={BgImage} alt="" />
          </figure>
        </div>
        <Container>
          <Row className="justify-content-sm-center">
            <Col sm={12} md={12} className="pl-sm-0">
              <div className="section-heading">
                <h2>
                  We <span className="color-brand">Focus</span> On
                </h2>
                <p className="tagline">"We bring new life to old homes"</p>
              </div>
            </Col>
            <Col sm={12} md={6} lg={4} className="pl-0">
              <Card
                imgsrc={Consult}
                title="Consult"
                content="A young person has different criterias than a retiree. We provide solid consulting with generational and lifestyle trends while considering the cheapest budget."
              />
            </Col>
            <Col sm={12} md={6} lg={4} className="pl-0">
              <Card
                imgsrc={Rethink}
                title="Design"
                content="“Home isn’t a place, it’s a feeling”. We design your houses in as much depth as possible so that it is as much futuristic having equally beautiful features.
                "
              />
            </Col>
            <Col sm={12} md={6} lg={4} className="pl-0">
              <Card
                imgsrc={Sketch}
                title="Refurbish"
                content="We refurbish your house with innovative ideas giving them a much mordern look while preserving the essence and feeling of your old house."
              />
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
}
export default Index;
