import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Image from "../../assets/uploads/featuredOne.png";

function Index() {
  return (
    <Fragment>
      <figure className="figure-card mr-4">
        <img src={Image} alt="figure card"></img>
        <span className="figure-card__zoom">
          <Link to="/">
            <i className="far fa-plus-square"></i>
          </Link>
        </span>
      </figure>
    </Fragment>
  );
}

export default Index;
