import React, { Fragment } from "react";
import Slide from "react-reveal";
import { Col, Container, Row } from "react-bootstrap";
import Hero from "../../assets/banner/vector-illustration.svg";
import Button from "../../Components/Button/index";
import BgImage from "../../assets/uploads/header-bg.png";
import { Link } from "react-router-dom";
function Index() {
  return (
    <Fragment>
      <section className="hero-banner">
        <div className="hero-banner-bg">
          <figure>
            <img src={BgImage} alt="" />
          </figure>
        </div>
        <Container>
          <Row>
            <Col sm={12} md={6} lg={6} className="align-self-center">
              <Slide left>
                <div className="hero-banner__wrapper-left">
                  <h1>
                    Turning <span className="color-brand">Dreams</span> Into{" "}
                    <span className="color-brand">Reality</span>
                  </h1>
                  <p className="text-gray my-sm-4 my-xs-3 my-3">
                    Our goal is to provide quality, on-time delivery to our
                    clients. We are a highly focused and dependable team that
                    has got a broad vision and crafty creativity like no other
                    teams around.
                  </p>
                  <a href="#contact">
                    <Button buttontype={"button__primary"} text="Get Started" />
                  </a>
                </div>
              </Slide>
            </Col>
            <Col sm={12} md={6} lg={6} className="text-right">
              <Slide right>
                <div className="hero-banner__wrapper-right">
                  <figure>
                    <img src={Hero} alt="site-branding-logo" />
                  </figure>
                </div>
              </Slide>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
}
export default Index;
