import React, { Fragment } from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Slide from "react-reveal";

const index = ({ link, key }) => {
  return (
    <Fragment>
      <Col sm={6} md={3} lg={3} className="mt-sm-5 mt-xs-5 mt-5">
        <Slide bottom>
          <div className="footer-card">
            <h3>{link.title}</h3>
            <ul className="footer-card__links">
              <li>
                <a href="/home/#works">{link.link1}</a>
              </li>
              <li>
                <a href="/about">{link.link2}</a>
              </li>
              <li>
                <a href="services">{link.link3}</a>
              </li>
              <li>
                <a href="/home/#our-works">{link.link4}</a>
              </li>
            </ul>
          </div>
        </Slide>
      </Col>
    </Fragment>
  );
};

export default index;
