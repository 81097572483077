import React, { useEffect } from "react";
import { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Banner from "../../Components/Banner/index";
import Image from "../../assets/uploads/about.jpg";
import MissionImage from "../../assets/uploads/mission.jpg";
import VisionImage from "../../assets/uploads/vision.jpg";

const Index = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      <Banner
        img={VisionImage}
        title="Who we are"
        desc="We make your dream come true"
        tag="About"
      />
      <section className="page-aboutus theme-section">
        <Container>
          <Row>
            <Col sm={12} md={6} lg={6} className="align-self-center p-sm-0 m-0">
              <div className="page-aboutus__wrapper-left mb-5 mr-lg-4">
                <div className="section-heading mb-5">
                  <h2>
                    <span className="color-brand">
                      <br></br>Build with us
                    </span>
                  </h2>
                  <p className="tagline">"Build your dream house"</p>
                </div>
                <p className="aboutus__wrapper-right__content">
                  Homes come in all varieties to fit all needs. Due to the rich
                  choice of existing housing stock, plus the high cost of
                  building a new-construction home, most would-be homeowners buy
                  an existing house. And this is not a bad choice at all. Most
                  houses are durable enough to last for decades, can readily be
                  remodeled, and they have the potential for increasing in
                  value.
                  <br></br>
                  <br></br>
                  Yet some of us want to build a new home exactly for our needs.
                  It comes with few, if any, hidden problems. New advantages are
                  added to the house and the old problems are left out.So, it is
                  better to build your own house from scratch as per your
                  requirement so that you can get the best out of it.
                </p>
              </div>
            </Col>
            <Col sm={12} md={6} lg={6} className="p-sm-0 m-0">
              <div className="page-aboutus__wrapper-right">
                <figure>
                  <img src={Image} alt="..."></img>
                </figure>
              </div>
            </Col>
            <Col
              sm={12}
              md={6}
              lg={6}
              className="p-sm-0 m-0 order-2 order-sm-1"
            >
              <div className="page-aboutus__wrapper-right">
                <figure>
                  <img src={MissionImage} alt="..."></img>
                </figure>
              </div>
            </Col>
            <Col
              sm={12}
              md={6}
              lg={6}
              className="align-self-center p-sm-0 m-0 order-1 order-sm-2"
            >
              <div className="page-aboutus__wrapper-left mb-5 ml-lg-4">
                <div className="section-heading">
                  <h2>
                    <span className="color-brand">Our Mission</span>
                  </h2>
                  <p className="tagline">"Renovate your lost dream"</p>
                </div>
                <p className="aboutus__wrapper-right__content">
                  Our main mission is to perform at the highest level for our
                  customers providing them with the highest level of
                  construction services at fair and competitive prices. We also
                  want to maintain highest level of professionalism, integrity,
                  honesty and fairness in our relationships with our suppliers,
                  subcontractors, professional associates and customers.
                  Furthermore, we also want to provide our employees with an
                  honest and helpful working environment where every employee
                  individually and collectively, can dedicate themselves to
                  providing our customers with exceptional workmanship,
                  extraordinary service, and professional integrity.
                </p>
              </div>
            </Col>
            <Col sm={12} md={6} lg={6} className="align-self-center p-sm-0 m-0">
              <div className="page-aboutus__wrapper-left mr-lg-4">
                <div className="section-heading mb-5">
                  <h2>
                    <span className="color-brand">Our Vision</span>
                  </h2>
                  <p className="tagline">"Renovate your lost dream"</p>
                </div>
                <p className="aboutus__wrapper-right__content">
                  We not only build new houses but also renovate your old house.
                  While you may not have money to build your new house but we
                  can renovate your house as per new specifications. Renovating
                  your house does not only make it look more attractive but it
                  can also increase the resale value of the house. There may be
                  some underlying issues in the house like electricity or
                  plumbing issues.While renovating your house we also deal with
                  the underlying issues so that your house is more energy
                  efficient.While renovating only sounds good for older houses
                  but a house with limited space and growing members should also
                  at one point renovate the house so that there is more space
                  for the family to have around the house.
                </p>
              </div>
            </Col>
            <Col sm={12} md={6} lg={6} className="p-sm-0 m-0">
              <div className="page-aboutus__wrapper-right">
                <figure>
                  <img src={VisionImage} alt="..."></img>
                </figure>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};
export default Index;
