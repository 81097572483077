import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import FooterCard from "../FooterCard/index";
import Logo from "../../assets/brand/big logo trayi@2x (1).svg";
import Slide from "react-reveal";

function Index() {
  const lists = [
    {
      id: 1,
      title: "Quick Links",
      link1: "How it works",
      link2: "Who are we",
      link3: "What we do",
      link4: "Featured Projects",
    },
    // {
    //   id: 2,
    //   title: "Our Services",
    //   link1: "Monitoring & supervision",
    //   link2: "Architectural design",
    //   link3: "Interior design",
    //   link4: "Survey",
    // },
  ];
  const footerList = lists.map((list) => (
    <FooterCard key={list.id} link={list} />
  ));
  return (
    <Fragment>
      <footer className="theme-section pb-2">
        <Container className="pt-5">
          <Row>
            <Col sm={6} md={3} lg={3} className="mt-5">
              <Slide bottom>
                <div className="branding">
                  <figure>
                    <Link to="/">
                      <img src={Logo} alt="site-branding"></img>
                    </Link>
                  </figure>
                  <p className="branding__info">
                    Communication is the key of success of any project. We are
                    available in different platforms for you. You can contact us
                    from below links.
                  </p>
                  <ul className="branding-social--icons mt-3">
                    <li className="mb-md-3 mr-xs-3 mr-3">
                      <a
                        href="https://www.facebook.com/trayimurti"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li className="mr-xs-3 mr-3">
                      <Link to="#">
                        <i className="fab fa-instagram"></i>
                      </Link>
                    </li>
                    <li className="mr-xs-3 mr-3">
                      <Link to="#">
                        <i className="fab fa-linkedin-in"></i>
                      </Link>
                    </li>
                    <li className="mr-xs-3 mr-3">
                      <Link to="#">
                        <i className="fab fa-twitter"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </Slide>
            </Col>
            {footerList}
            <Col sm={6} md={3} className="mt-xs-5 mt-5">
              <div className="footer-card">
                <h3>Our services</h3>
                <ul className="footer-card__links">
                  <li>
                    <a href="/services">Site Supervision</a>
                  </li>
                  <li>
                    <a href="/services">Architectural Design</a>
                  </li>
                  <li>
                    <a href="/services">Interior Design</a>
                  </li>
                  <li>
                    <a href="/services">Survey</a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col sm={6} md={3} lg={3} className="mt-xs-5 mt-5">
              <Slide bottom>
                <div className="footer-card contact-info">
                  <h3>Contact Info</h3>
                  <ul className="footer-card__links">
                    <li>
                      <a href="mailto:yourdomain.com">
                        trayimurticonsultant@gmail.com
                      </a>
                    </li>
                    <li>
                      <a href="tel:+977 985-7072892">+977 985-7072892</a>
                    </li>
                    <li>Horizon Chowk, Rupandehi, Nepal.</li>
                  </ul>
                </div>
              </Slide>
            </Col>
            <Col sm={12} md={12} lg={12} className="mt-xs-5 mt-5">
              <Slide bottom>
                <div className="footer-copyright text-center mt-md-3">
                  <p>
                    © copyright 2021. Designed by
                    <a
                      href="https://www.buddhainnovations.com/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <span className="color-brand">
                        Buddha Innovations Tech
                      </span>
                    </a>
                  </p>
                </div>
              </Slide>
            </Col>
          </Row>
        </Container>
      </footer>
    </Fragment>
  );
}
export default Index;
