import React, { Fragment, useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../../assets/brand/big logo trayi@2x (1).svg";
function Index() {
  const [scrolled, setScrolled] = useState(false);
  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 300) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });
  let navbarClasses = ["header"];
  if (scrolled) {
    navbarClasses.push("scrolled");
  }

  const [isMobile, setIsMobile] = useState(false);

  return (
    <Fragment>
      <header className={navbarClasses.join(" ")}>
        <Container>
          <Row className="justify-content-md-center align-items-center">
            <Col sm={6} md={4} lg={4}>
              <div className="branding">
                <Link to="/">
                  <img src={Logo} alt="site-branding"></img>
                </Link>
              </div>
            </Col>
            <Col sm={6} md={8} lg={8}>
              <nav>
                <ul
                  className={isMobile ? "nav-links-mobile" : "nav-links"}
                  onClick={() => setIsMobile(false)}
                >
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/home/#works">How it works?</a>
                  </li>
                  <li>
                    <a href="/services">Our Services</a>
                  </li>
                  <li>
                    <a href="/about">Who we are</a>
                  </li>
                  <li>
                    <a href="/home/#our-works">Our Works</a>
                  </li>
                  <li>
                    <a href="/home/#contact">Contact Us</a>
                  </li>
                </ul>
                <button
                  className="mobile-menu-icon"
                  onClick={() => setIsMobile(!isMobile)}
                >
                  {isMobile ? (
                    <i className="fas fa-times"></i>
                  ) : (
                    <i className="fas fa-bars"></i>
                  )}
                </button>
              </nav>

              {/* Mobile Menu */}
              {/* <Navbar bg="light" expand="lg">
                  <Navbar.Brand href="#home">
                  <img src={Logo} alt="site-branding"></img>
                </Navbar.Brand>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                      <Nav.Link href="#home">Home</Nav.Link>
                      <Nav.Link href="#works">How it works?</Nav.Link>
                      <Nav.Link href="#link">Our Services</Nav.Link>
                      <Nav.Link href="#link">Who we are</Nav.Link>
                      <Nav.Link href="#link">Our Works</Nav.Link>  
                      <Nav.Link href="#">Contact Us</Nav.Link>                  
                    </Nav>
                  </Navbar.Collapse>
                </Navbar> */}
            </Col>
          </Row>
        </Container>
      </header>
    </Fragment>
  );
}
export default Index;
