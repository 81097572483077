import React from "react";
import { Fragment, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Card from "../../Components/Card/index";
import Banner from "../../Components/Banner/index";
import Analysis from "../../assets/uploads/analysis.svg";
import Architecture from "../../assets/uploads/architecture.svg";
import Armchair from "../../assets/uploads/armchair.svg";
import Clipboard from "../../assets/uploads/clipboard.svg";
import MissionImage from "../../assets/uploads/mission.jpg";
import ServiceImage from "../../assets/uploads/service.jpg";
const Index = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      <Banner
        img={ServiceImage}
        title="What we do"
        desc="We make your dream come true"
        tag="services"
      />
      <section className="page-service theme-section">
        <Container>
          <Row className="justify-content-center">
            <Col sm={12} md={6} lg={4}>
              <Card
                imgsrc={Armchair}
                title="Site Supervision"
                content="Definition and monitoring of procedures and instructions; -Time, cost and quality monitoring; -Coordination and verification of test & acceptance procedures of (sub)systems; -Coordination and support of hand-over procedures to Client; -Arrange for coordination meetings and reporting to project management."
              />
            </Col>
            <Col sm={12} md={6} lg={4}>
              <Card
                imgsrc={Armchair}
                title="Interior Design"
                content="Interior design is the art and science of understanding people's behavior to create functional spaces within a building. .The list which puts down the interior design services for both residential and commercial space projects. - General Interior Design Consultations - Civil work - Space planning and space management - Painting and polishing - Wall cover and wallpaper installation - Furniture layout and placement design - Purchasing and shopping services (for accessories, furniture, curtains, etc.) by helping you make wise choices"
              />
            </Col>
            <Col sm={12} md={6} lg={4}>
              <Card
                imgsrc={Architecture}
                title="Architectural Design"
                content="We provide design, preparation of construction documents, and construction administration. We also provide a wide variety of services including feasibility studies, architectural programming and project management.
                "
              />
            </Col>
            <Col sm={12} md={6} lg={4}>
              <Card
                imgsrc={Clipboard}
                title="Survey"
                content="Science and art of making all essential measurements, or to establish the position of points or details."
              />
            </Col>
            <Col sm={12} md={6} lg={4}>
              <Card
                imgsrc={Armchair}
                title="Construction and Building Design (नक्शा)"
                content="All types of building design suitable for you(Commercial/Residential Building; RCC/Masonry/Steel structures - Identifying activities and resources required to make the design a physical reality - 2D Master Plan - Municipality Drawing & Pass - 3D exterior & interior design"
              />
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};
export default Index;
