import { Modal, Button, Form } from "react-bootstrap";
import React, { Fragment, useState } from "react";
import emailjs from "emailjs-com";
import { Col } from "react-bootstrap";
// import Button from "../../Components/Button/index";

function Index() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_ntchgsj",
        "template_k3zfgok",
        e.target,
        "user_XAr5gkEtL8buGDL1D2G49"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    window.alert("Congratulations! Your message has been successfully sent.");

    e.target.reset();
  }

  return (
    <Fragment>
      <Col md={{ span: 6, offset: 3 }}>
        <div className="contact-card text-center" id="contact">
          <h2>
            <span className="color-brand">Connect</span> with us
          </h2>
          <button variant="primary" onClick={handleShow}>
            Contact Us
          </button>

          {/* <Button
            buttontype={"button__primary"}
            text="Contact Us"
            onClick={handleShow}
          /> */}
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Contact Us</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* Contact form */}
              <Form onSubmit={sendEmail}>
                <Form.Row>
                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      name="email"
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Full name"
                      name="fname"
                      required
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Group controlId="formGridAddress1">
                  <Form.Label>Subject</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Your subject"
                    name="subject"
                    required
                  />
                </Form.Group>

                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Message"
                    name="message"
                    required
                  />
                </Form.Group>

                <Form.Group id="formGridCheckbox">
                  <Form.Check
                    type="checkbox"
                    label="I agree to the Terms and Conditions"
                    required
                  />
                </Form.Group>

                <Button buttontype={"button__primary"} type="submit">
                  Submit
                </Button>
              </Form>
            </Modal.Body>
            {/* <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleClose}>
                Save Changes
              </Button>
            </Modal.Footer> */}
          </Modal>
        </div>
      </Col>
    </Fragment>
  );
}

export default Index;
