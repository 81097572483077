import React, { Fragment } from "react";
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import Slide from "react-reveal";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import FigureCard from "../../Components/FigureCard/index";
function Index() {
  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    autoplay: true,
    speed: 800,
    autoplaySpeed: 8000,
    cssEase: "linear",
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          dots: true,
          arrows: false,
          autoplay: false,
        },
      },
    ],
  };
  return (
    <Fragment>
      <section className="theme-section featured-projects" id="our-works">
        <div className="section-heading pl-md-5 ml-md-5 pl-sm-3 pl-xs-3 pl-2">
          <h2>
            Our <span className="color-brand">Featured Projects</span>
          </h2>
          <p className="tagline">"We dig what you dream"</p>
        </div>
        <Container className="ml-lg-0 pl-lg-0">
          <Slide left>
            <Slider className="featured-slider" {...settings}>
              <FigureCard />
              <FigureCard />
              <FigureCard />
              <FigureCard />
              <FigureCard />
            </Slider>
          </Slide>
        </Container>
      </section>
    </Fragment>
  );
}
export default Index;
