import React, { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Card from "../../Components/Card/index";
import Button from "../../Components/Button/index";
import Analysis from "../../assets/uploads/analysis.svg";
import Architecture from "../../assets/uploads/architecture.svg";
import Armchair from "../../assets/uploads/armchair.svg";
import Clipboard from "../../assets/uploads/clipboard.svg";
import ServiceImage from "../../assets/uploads/service-grp.png";
import { Link } from "react-router-dom";
function Index() {
  return (
    <Fragment>
      <section
        className="theme-section services"
        style={{
          backgroundImage: `url(${ServiceImage})`,
          backgroundRepeat: "no-repeat",
        }}
        id="services"
      >
        <Container>
          <Row>
            <Col
              sm={12}
              md={12}
              lg={3}
              className=" align-self-center pl-md-0 mb-5"
            >
              <div className="section-heading">
                <h2>
                  What we do<br></br>Our
                  <span className="color-brand"> Services</span>
                </h2>
                <p className="tagline">"We dig what you dream"</p>
              </div>
              <Link to="/services">
                <Button buttontype={"button__secondary"} text="All Services">
                  {" "}
                </Button>
              </Link>
            </Col>
            <Col sm={12} md={6} lg={4}>
              <Card
                imgsrc={Analysis}
                title="Site Supervision"
                content="-Definition and monitoring of procedures and instructions; -Time, cost and quality monitoring; -Coordination and verification of test & acceptance procedures of (sub)systems; -Coordination and support of hand-over procedures to Client; -Arrange for coordination meetings and reporting to project management.
"
              />
              <Card
                imgsrc={Armchair}
                title="Interior Design"
                content="Interior design is the art and science of understanding people's behavior to create functional spaces within a building. .The list which puts down the interior design services for both residential and commercial space projects. - General Interior Design Consultations - Civil work - Space planning and space management - Painting and polishing - Wall cover and wallpaper installation - Furniture layout and placement design - Purchasing and shopping services (for accessories, furniture, curtains, etc.) by helping you make wise choices"
              />
            </Col>
            <Col sm={12} md={6} lg={4}>
              <Card
                imgsrc={Architecture}
                title="Architectural Design"
                content="We provide design, preparation of construction documents, and construction administration. We also provide a wide variety of services including feasibility studies, architectural programming and project management.
                "
              />
              <Card
                imgsrc={Clipboard}
                title="Survey"
                content="Science and art of making all essential measurements, or to establish the position of points or details."
              />
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
}
export default Index;
