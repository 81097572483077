import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

//body components here
import Home from "../../Containers/Home/index";
import Services from "../../Containers/Services/index";
import Contact from "../../Components/ContactCard/index";
import About from "../../Containers/About/index";

//header
import Header from "../../Components/Header/index";
import Footer from "../../Components/Footer/index";
//messenger chat
import MessengerCustomerChat from "react-messenger-customer-chat";
const index = () => {
  return (
    <div>
      <Header />
      <main>
        <Switch>
          <Redirect exact from="/" to="/home" />
          <Route exact path="/home" component={Home} />
          <Route exact path="/services" component={Services} />
          <Route exact path="/about" component={About} />
          {/* <Route exact path='/' component={Home} />
          <Route exact path='/product' component={Product} />
          <Route exact path='/contact' component={Contact} />
          <Route exact path='/cart' component={Cart} />
          <Route exact path='/checkout' component={Checkout} />
          <Route exact path='/profile' component={Profile} /> */}
        </Switch>
      </main>
      <Contact />
      <Footer />
      <MessengerCustomerChat
        pageId="101636491702052"
        appId="1452255361774357"
      />
    </div>
  );
};

export default index;
