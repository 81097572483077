import React, { Fragment } from "react";
import TestimonialCard from "../../Components/TestimonialCard/index";
import Slider from "react-slick";
import Slide from "react-reveal";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { Container } from "react-bootstrap";
import Bikram from "../../assets/clients/client1.jpg";
import Till from "../../assets/clients/client2.jpg";
import Suresh from "../../assets/clients/client3.jpg";
import Jay from "../../assets/clients/client4.jpg";
function Index() {
  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    autoplay: false,
    speed: 1200,
    autoplaySpeed: 500,
    cssEase: "linear",
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };
  return (
    <Fragment>
      <section className="theme-section testimonial">
        <div className="section-heading text-center">
          <h2>
            What's Our<span className="color-brand"> Clients Say</span>
          </h2>
          <p className="tagline">“Let’s build a better tommorrow”</p>
        </div>
        <Container>
          <Slide bottom>
            <Slider className="testimonial-slider" {...settings}>
              <TestimonialCard
                title={"Till Somare"}
                desc={
                  "This is the best construction company with young, energetic and good brained engineers."
                }
                post={"British Army"}
                image={Till}
              />
              <TestimonialCard
                title={"Suresh Gyawali"}
                desc={
                  "I thought building a house was a tedious work to do but Trayimurti construction provided us with some really needed insights and expertise and built us our dream house"
                }
                post={"Indian Army"}
                image={Suresh}
              />
              <TestimonialCard
                title={"Bikram Chhetri"}
                desc={
                  "Renovating my house was a smooth experience because Trayimurti did it with perfection. Loved their work! If I am doing some construction works it's definitely with Trayimurti."
                }
                image={Bikram}
                post={"Auditor, BM consultant"}
              />
              <TestimonialCard
                title={"Jay Ram Bhusal"}
                desc={
                  "This team is really reliable as I did not have to worry about what was going on around the working site. The team is very good at communication and it was a nice experience working with them."
                }
                image={Jay}
                post={"Korea (Foreign Employment)"}
              />
              {/*<TestimonialCard />
              <TestimonialCard />
              <TestimonialCard />
              <TestimonialCard /> */}
            </Slider>
          </Slide>
        </Container>
      </section>
    </Fragment>
  );
}

export default Index;
