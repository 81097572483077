import React, { Fragment, useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import Slide from "react-reveal";

const images = [
  { id: "1", imageName: "featuredOne.png", tag: "interior" },
  { id: "2", imageName: "featuredTwo.png", tag: "exterior" },
  { id: "3", imageName: "featuredThree.png", tag: "architecture" },
  { id: "4", imageName: "featuredThree.png", tag: "architecture" },
  { id: "5", imageName: "featuredTwo.png", tag: "architecture" },
  { id: "6", imageName: "featuredThree.png", tag: "architecture" },
  { id: "7", imageName: "featuredTwo.png", tag: "interior" },
  { id: "8", imageName: "featuredOne.png", tag: "exterior" },
];

const Index = () => {
  const [tag, setTag] = useState("all");
  const [filteredImages, setfilteredImages] = useState([]);

  useEffect(() => {
    tag === "all"
      ? setfilteredImages(images)
      : setfilteredImages(images.filter((image) => image.tag === tag));
  }, [tag]);
  return (
    <Fragment>
      <section className="theme-section gallery">
        <Container>
          <div className="section-heading text-center">
            <h2>
              <span className="color-brand">Houses </span>of your Dream
            </h2>
            <p className="tagline">"We dig what you dream"</p>
          </div>

          <div className="button-group mb-4">
            <TagButton name="all" handlesettag={setTag} />
            <TagButton name="interior" handlesettag={setTag} />
            <TagButton name="exterior" handlesettag={setTag} />
            <TagButton name="architecture" handlesettag={setTag} />
          </div>
          <div className="gallery-grid">
              {filteredImages.map((image) => (
                <Slide bottom>
                <figure key={image.id}>
                  <img src={`/Images/${image.imageName}`} alt="" />
                  {/* {image.imageName} */}
                </figure>
                </Slide>
              ))}
          </div>
        </Container>
      </section>
    </Fragment>
  );
};
const TagButton = ({ name, handlesettag }) => {
  return (
    <button onClick={() => handlesettag(name)}>{name.toUpperCase()}</button>
  );
};
export default Index;
