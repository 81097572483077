import React, { Fragment } from "react";
import Reveal from "react-reveal";
function Index({ imgsrc, title, content }) {
  return (
    <Fragment>
      <Reveal bottom>
        <div className="card text-center">
          <figure>
            <img src={imgsrc} alt="card-brand-icon"></img>
          </figure>
          <h3>{title}</h3>
          <p>{content}</p>
        </div>
      </Reveal>
    </Fragment>
  );
}
export default Index;
