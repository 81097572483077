import React from "react";
import { Fragment } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Slide from "react-reveal";
const Index = ({ img, title, desc, tag }) => {
  return (
    <Fragment>
      <Slide top>
        <section
          className="page-banner theme-section"
          style={{ backgroundImage: `url(${img})` }}
        >
          <Container>
            <div className="page-banner__info">
              <div className="section-heading">
                <h1>
                  <span className="color-brand">{title}</span>
                </h1>
                <p className="tagline">{desc}</p>
              </div>
              <div className="page-banner__info-breadcrumb">
                <Link to="/">Home</Link>
                <span>{tag}</span>
              </div>
            </div>
          </Container>
        </section>
      </Slide>
    </Fragment>
  );
};
export default Index;
