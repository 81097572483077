import React, { Fragment, useEffect } from "react";
import Slide from "react-reveal";
import Image from "../../assets/uploads/figure-shape.png";
import BgImage from "../../assets/uploads/aboutus-bg.png";
import Button from "../../Components/Button/index";
import { Link } from "react-router-dom";
const Index = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      <section
        className="theme-section aboutus"
        id="about"
        style={{
          backgroundImage: `url(${BgImage})`,
          backgroundPosition: "50% -30%",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="aboutus__wrapper-left">
          <Slide left>
            <figure>
              <img src={Image} alt="..."></img>
            </figure>
          </Slide>
        </div>
        <Slide right>
          <div className="aboutus__wrapper-right">
            <div className="section-heading">
              <h2>
                #1 Ranked{" "}
                <span className="color-brand">
                  <br></br>Construction Agency
                </span>
              </h2>
              <p className="tagline">"Renovate your lost dream"</p>
            </div>
            <p className="aboutus__wrapper-right__content">
            We are here to helps you through design, consultation, construction, interior, renovation and more.<br></br>
              <br></br>Group of Architects, Engineers, Contractors, Suppliers around the nation are standby to turn dreams into reality.
            </p>
            <Link to="/about">
              <Button buttontype={"button__primary"} text="Learn More" />
            </Link>
          </div>
        </Slide>
      </section>
    </Fragment>
  );
};
export default Index;
